<template>
  <div>
    <div
      :class="
        isExpand
          ? 'rounded-bl-none border-b-none rounded-md rounded-br-none '
          : 'rounded-md'
      "
      class="border border-1 cursor-pointer rounded-md p-[1rem]"
      @click="$emit('handleExpandTemplate')"
    >
      <div class="flex justify-between">
        <div class="flex gap-3">
          <div>
            Gunakan Template
          </div>
          <div
            v-if="!templateSelected"
            :class="
              isExpand
                ? 'bg-[#F4F4F4] text-[#C2C2C2] border border-1'
                : 'bg-primary text-white '
            "
            class="rounded-md  text-xs p-[3px] "
          >
            <b-icon-clipboard-minus class="mr-[2px] " /> Template
          </div>
          <div
            v-else
            class="rounded-md flex gap-2 items-center bg-[#DCF3EB] text-xs p-[4px] "
          >
            <div class="text-[#34A770]">
              {{ templateSelected.name }}
            </div>
            <b-icon
              icon="check-circle-fill"
              class="text-[#34A770] text-lg"
            />
          </div>
        </div>
        <div>
          <b-icon-x
            v-if="isExpand"
            class="mr-[2px] text-black"
          />
        </div>
        <div
          v-if="templateSelected && !isExpand"
          class="border gap-1 flex rounded-md border-2 p-[3px] border-primary"
        >
          <b-img
            v-if="!isExpand"
            class="max-w-[13px]"
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
          />
          <div
            v-if="!isExpand"
            class="text-primary text-sm"
          >
            Ganti
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isExpand"
      :class="!templateList.length? 'flex items-center justify-center ' : ''"
      class="border h-[20rem] overflow-y-auto border-1 p-1 border-t-none rounded-tl-none rounded-tr-none rounded-md"
    >
      <div v-if="templateList.length">
        <BOverlay
          id="infinite-list"
          variant="light"
          :show="isLoadingTemplate"
          spinner-variant="primary"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <div>
            Silahkan pilih template
          </div>
          <div
            v-for="template in templateList"
            :key="template.id"
            class="grid cursor-pointer mt-1 gap-2"
          >
            <div
              :class="
                select === template.id
                  ? 'border-primary border-1 border'
                  : 'border border-1 '
              "
              class="p-[0.6rem] cursor-pointer flex justify-between items-center rounded-md  "
            >
              <div @click="$emit('handleSelectTemplate',template)">
                <div class="text-[500] text-black text-[12px]">
                  {{ template.name }}
                </div>
                <div class="text-[10px]">
                  {{ defineTypeTemplate(template.type) }} -
                  {{ template.created_at }}
                </div>
              </div>
              <div>
                <b-icon
                  v-if="select === template.id"
                  icon="check-circle-fill"
                  class="text-primary"
                />
                <b-icon
                  v-else
                  v-b-modal.modal-delete-template
                  icon="trash"
                  class="focus:outline-none"
                  @click="$emit('handleDeleteTemplate',template.id)"
                />
              </div>
            </div>
          </div>
        </BOverlay>
      </div>
      <div
        v-else
        class="p-2 flex items-center justify-center"
      >
        <div class="text-center ">
          Belum Ada Template
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    templateSelected: {
      type: [String, Object],
      default: '',
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
    templateList: {
      type: Array,
      default: () => [],
    },
    isLoadingTemplate: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    defineTypeTemplate(type) {
      const typeTemplate = {
        TEXT_MEDIA: 'Text Media Message',
        TEXT: 'Text Message',
        MEDIA: 'Media Message',
      }
      return typeTemplate[type]
    },
  },
}
</script>
