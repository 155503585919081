<template>
  <div>
    <div
      :id="toolbarsId"
      class="flex gap-2 p-[10px]"
      :class="{'bg-[#DCF3EB] justify-center rounded-full': forToolbar}"
    >
      <div
        v-for="(toolbar, index) in !forToolbar? toolbars : toolbars.slice(0, -1)"
        :key="index"
        class="flex justify-center"
      >
        <button
          class="bold bg-[#F4F4F4]"
          @click="toolbarClick(toolbar.type)"
        >
          <b-img
            class=""
            :src="toolbar.icon"
            :alt="toolbar.type"
          />
        </button>
      </div>
      <div
        class="relative left-[-2rem] top-[2rem] "
        :hidden="dialogHidden"
      >
        <VEmojiPicker
          class="absolute z-50"
          label-search="Search"
          @select="selectEmoji($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { VEmojiPicker } from 'v-emoji-picker'
import { toolbars } from '../config'

export default {
  components: {
    VEmojiPicker,
  },
  props: {
    forToolbar: {
      type: Boolean,
      default: true,
    },
    toolbarsId: {
      type: String,
      default: '',
    },
    isEditorEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toolbars,
      dialogHidden: true,
    }
  },
  methods: {
    toolbarClick(type) {
      if (type === 'emoji' && this.isEditorEnabled) {
        this.toogleDialogEmoji()
      } else {
        this.formatText(type)
      }
    },
    toogleDialogEmoji() {
      this.dialogHidden = !this.dialogHidden
    },
    formatText(type) {
      this.$emit('formatText', type)
    },
    selectEmoji(emoji) {
      this.$emit('selectEmoji', emoji)
      this.toogleDialogEmoji()
    },
  },
}
</script>
