<template>
  <b-modal
    id="modal-spintax"
    hide-footer
    hide-header
    :no-close-on-backdrop="true"
    size="lg"
    centered
  >
    <div class="p-1">
      <div class="text-center font-[600] text-[24px] font-bold">
        Spintax Pesan
      </div>
      <div class="text-center mb-4 my-2">
        Pilih variasi konten dan modifikasi sesuai kebutuhanmu untuk <br> kurangi rasio banned saat Broadcast
      </div>
      <div
        v-for="(content, index) in contents"
        :key="index"
        class="flex mb-2 w-full items-center gap-3"
      >
        <b-form-checkbox
          :id="`checkbox-${index}`"
          v-model="content.checked"
          :disabled="index === 0 || content.onEdit === true "
          :name="`checkbox-${index}`"
          :class="{'opacity-0' :index === 0}"
        />
        <div class="w-[100%]">
          <UiInputHtml
            :checked="content.checked"
            :for-spintax="true"
            :custom-class="'min-h-[195px] rounded-tl-none rounded-tr-none'"
            :content="content.ori"
            :variant-name="index === 0 ? 'Pesan Kamu': `Variant ${index}`"
            :is-editor-enabled="index !== 0"
            @update:edit="(value) => { content.onEdit = value}"
            @update:content="(value) => { content.ori = value}"
            @update:length="(value) => { content.lengths = value}"
            @handleCancel="() => {content.ori = content.copy}"
          />
        </div>
      </div>
      <BRow class="justify-center mt-3">
        <BCol
          lg="8"
          class="flex gap-3 items-center justify-center"
        >
          <b-button
            block
            variant="outline-primary"
            class="mb-[-10px] !h-[37.6px]"
            @click="handleCancel"
          >
            Batal
          </b-button>
          <b-button
            block
            :disabled="!disable"
            :variant="disable ? 'primary' : 'light'"
            class=""
            @click="handleActionUse"
          >
            Gunakan Varian
          </b-button>
        </BCol>
      </BRow>
    </div>
  </b-modal>
</template>

<script>

import UiInputHtml from '@/views/pages/komship/customer/Broadcast/components/UiInputHtml.vue'

export default {
  components: {
    UiInputHtml,
  },
  props: {
    contents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      disable: false,
    }
  },
  watch: {
    contents: {
      handler(newContents) {
        const check = newContents.some(item => item.checked === true)
        if (check) {
          const checked = newContents.filter(item => item.checked === true)
          const anyEmptyField = checked.filter(item => item.lengths < 20)
          const onEdit = checked.filter(item => item.onEdit === true)
          this.disable = !anyEmptyField.length && checked.length && !onEdit.length
        } else {
          this.disable = check
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleCancel() {
      this.$emit('handleCancelModal')
      this.$bvModal.hide('modal-spintax')
    },
    handleActionUse() {
      const contentChecked = this.contents.filter(e => e.checked === true)
      const content = contentChecked.map(e => ({
        content: e.ori,
      }))
      this.$emit('handleActionUse', content)
      this.$bvModal.hide('modal-spintax')
    },
  },
}
</script>
