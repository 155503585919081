<template>
  <div>
    <div class="flex my-2 justify-between ">
      <div class="flex items-center text-black">Spintax Pesan <span>
        <img
          id="spintaxTooltip"
          class="align-self-center ml-[2px]"
          width="16"
          src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
        >
        <b-popover
          triggers="hover"
          target="spintaxTooltip"
          placement="top"
        >Kurangi rasio banned dengan varian content yang dioptimasi AI</b-popover></span>
      </div>
      <div class="flex items-center gap-1">
        <b-spinner
          v-if="isLoading"
          small
          label="Small Spinner"
        />
        <b-form-checkbox
          v-model="checked"
          :disabled="handleDisable()"
          name="check-button"
          size="md"
          switch
          @change="handleSpintax"
        />
      </div>
    </div>
    <div>
      <SpintaxModal
        :contents="spintaxContent"
        @handleActionUse="onHandleActionUse"
        @handleCancelModal="onHandleCancelModal"
      />
    </div>
  </div>
</template>

<script>
import SpintaxModal from '@/views/pages/komship/customer/Broadcast/components/SpintaxModal.vue'
import { apiSpintax } from '../service/api.broadcast.service'

export default {
  components: {
    SpintaxModal,
  },
  props: {
    templateTypeId: {
      type: Number,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      spintaxContent: [],
      usedContent: [],
      checked: false,
    }
  },
  methods: {
    onHandleCancelModal() {
      this.checked = false
    },
    async handleSpintax() {
      try {
        if (this.usedContent.length) {
          this.usedContent = ''
          this.$emit('handleCancelUseSpintax')
        } else {
          this.isLoading = true
          const param = {
            text: this.content,
          }
          const { data } = await apiSpintax({ param })
          this.spintaxContent = data.data.map(e => ({
            ori: e.content,
            copy: e.content,
            checked: false,
            onEdit: false,
            lengths: this.getLengthText(e.content),
          }))
          if (data) {
            this.$bvModal.show('modal-spintax')
          }
          this.isLoading = false
        }
      } catch (err) {
        console.error(err)
        this.$toast_error({ message: 'Gagal generate spintax, silahkan coba lagi!' })
        this.checked = false
        this.isLoading = false
      }
    },
    getLengthText(htmlString) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlString, 'text/html')
      const text = doc.body.textContent || ''
      return text.length
    },
    handleDisable() {
      return this.templateTypeId === 1 || !this.content || this.getLengthText(this.content) < 20
    },
    onHandleActionUse(value) {
      this.usedContent = value
      this.$emit('handleActionUse', value)
    },
  },
}

</script>
