<template>
  <div id="template-notification">
    <div class="flex p-[24px]  items-center gap-2 ">
      <b-img
        class="align-self-start w-[2.8rem] md:w-[unset] custom-profile"
        src="https://storage.googleapis.com/komerce/assets/icons/profile-placehold.svg"
      />
      <div class="custom-template tri-right left-top">
        <div
          v-if="
            idTemplate === 0 ||
              idTemplate === 2
          "
          :class="
            content
              ? 'max-w-full lg:max-w-[33rem] w-[100%] lg:w-[33rem] bg-white p-1 rounded-lg'
              : 'd-none'
          "
        >
          <fieldset
            id="preview-message"
            class="text-left min-w-[100%] w-full bg-white max-w-[430px] rounded-lg "
            v-html="content"
          />
        </div>
        <div
          v-if="
            idTemplate === 1 ||
              idTemplate === 2
          "
          :class="
            caption || urlMediaLocal
              ? 'bg-white   mt-[5px]  p-1 rounded-lg max-w-full lg:max-w-[33rem] w-[100%] lg:w-[33rem]'
              : 'd-none'
          "
        >
          <b-img
            fluid
            class=" object-contain"
            :src="urlMediaLocal"
            alt=""
          />
          <fieldset
            id="preview-message"
            class="text-left min-w-[100%] w-full bg-white max-w-[430px] rounded-lg "
            v-html="caption"
          />
        </div>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    caption: {
      type: String,
      default: '',
    },
    idTemplate: {
      type: Number,
      default: null,
    },
    urlMediaLocal: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
}

</script>

<style lang="scss" scoped>
#template-notification {
  min-height: 600px;
  background-image: url('https://storage.googleapis.com/komerce/assets/elements/whatsapp-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
}

.character-counter {
  position: absolute;
  right: 2.5rem;
  bottom: 13.7rem;
}
.bottom-mode {
  position: absolute;
  right: 2.5rem;
  bottom: 8rem;
}

.ql-formats {
  display: flex !important;
  gap: 1rem !important;
}
#preview-message ::v-deep p {
  margin-bottom: 0px !important;
}

</style>
