<template>
  <div>
    <div class="mt-1">
      <h5 class="text-black">
        <strong>Penerima</strong>
      </h5>
      <div>Penerima Broadcast</div>
    </div>
    <div class="border border-2 rounded-lg md:p-2 p-1 mt-2 border-[#E2E2E2]">
      <div class="mb-1">
        Silahkan pilih penerima
      </div>
      <div class="lg:w-[35%]">
        <BInputGroup class="input-group-merge ">
          <BInputGroupPrepend is-text>
            <b-img
              fluid
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
              alt=""
            />
          </BInputGroupPrepend>
          <BFormInput
            :value="searchReceiver"
            size="md"
            placeholder="Cari Penerima"
            style="padding: 8px 2px"
            @input="$emit('update:searchReceiver', $event)"
          />
        </BInputGroup>
      </div>
      <div
        v-if="!groupContact.length"
        class="border border-2 rounded-lg md:p-2 mt-2 border-[#E2E2E2]"
      >
        <div class=" p-3 text-center">
          <b-img
            center
            class="opacity-25 w-[45px]"
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/add-circle.svg"
            alt=""
          />
          <div class="mt-1">
            <small class="text-center">
              Tidak ada data untuk ditampilkan
            </small>
          </div>

          <b-button
            size="sm"
            class="mt-1"
            variant="primary"
            @click="$router.push('/grup-contact')"
          ><div class="flex gap-3 items-center justify-center">
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/add.svg"
              alt=""
            >
            <div>Tambah Group Kontak</div>
          </div>
          </b-button>
        </div>
      </div>
      <div
        v-else
        id="scroll"
        ref="scroll"
        class="border h-[50vh] overflow-y-auto border-2 p-2  rounded-lg mt-2 border-[#E2E2E2]"
        @scroll="handleScroll"
      >
        <BOverlay
          variant="light"
          :show="isLoading"
          spinner-variant="primary"
          blur="0"
          class=""
          opacity=".5"
          rounded="sm"
        >
          <div
            v-for="(group, index) in groupContact"
            :key="index"
            class="flex items-center gap-1"
          >
            <b-form-checkbox
              v-model="groupContactSelected"
              name="checkbox-1"
              class="mt-[-1rem]"
              :value="group"
              @change="updateGroupContactSelected"
            />
            <label
              class=" mb-1 text-primary text-xs"
              for="gridCheck"
            >
              <div class="text-[12px] text-[400] text-[#333333]">{{ group.name }}</div>
              <small class="text-[10px] text-[400] text-primary">{{ group.total_contact }} kontak</small>
            </label>
          </div>
        </BOverlay>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BFormCheckbox,
  BSpinner,
  BModal,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BButton,
    BImg,
  },
  props: {
    groupContact: {
      type: [Array,
        String],
      default: () => [],
    },
    searchReceiver: {
      type: String,
      default: '',
    },
    groupContactSelected: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localGroupContactSelected: this.groupContactSelected.slice(),
    }
  },
  methods: {
    updateGroupContactSelected(newValue) {
      this.$emit('update:groupContactSelected', newValue)
    },
    handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } = this.$refs.scroll
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.$emit('nextDataGroup')
      }
    },

  },
}
</script>
