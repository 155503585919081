import { newAxiosIns } from '@/libs/axios'

const { VUE_APP_BASE_URL_NEW } = process.env

export function apiGetGroupContact(search, cursor, limit) {
  return newAxiosIns.get(`/komchat-api/api/v1/group-contact/list?cursor=${cursor}&limit=${limit}&search=${search}`)
}

export function apiImportContactFromExcel({ formData }) {
  return newAxiosIns.post('/komchat-api/api/v1/contact/import/excel', formData)
}

export function apiDownloadTemplateImportExcel() {
  return newAxiosIns.get('/komchat-api/api/v1/contact/import/excel/template', {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  })
}
