<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div>
    <b-card>
      <div class="">
        <div class="flex mb-2 gap-3 items-center">
          <b-img
            class="cursor-pointer mt-[-0.5rem]"
            src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
            alt=""
            @click="$router.back()"
          />
          <h4>
            <strong class="text-black text-2xl">Broadcast - Kirim Pesan</strong>
          </h4>
        </div>
        <!-- sender -->
        <SenderMessage
          :disabledSelectTypeMessage="spintaxContent.length? true : false"
          :template-name="templateName"
          :sender-number="senderNumber"
          :options-template="optionsTemplate"
          :option-sender-number="optionSenderNumber"
          :type-template-message="typeTemplateMessage"
          @update:typeTemplateMessage="val => (typeTemplateMessage = val)"
          @update:senderNumber="val => (senderNumber = val)"
          @update:templateName="val => (templateName = val)"
        />
        <!-- receiver -->
        <ReceiverMessage
          :is-loading="isLoadingGroupContact"
          :search-receiver="searchReceiver"
          :group-contact="groupContact"
          @update:searchReceiver="handleSearchReceiver"
          @update:groupContactSelected="value => (groupContactSelected = value)"
          @nextDataGroup="getNextGroupContact"
        />
      </div>
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <div class="text-black font-semibold">
            {{ typeTemplateMessage.label }}
          </div>
        </b-col>
        <b-col>
          <div class="text-black preview font-semibold">
            Preview
          </div>
        </b-col>
      </b-row>
      <hr class="my-2">
      <!-- template -->
      <b-row>
        <b-col>
          <SelectTemplate
            :template-selected="templateSelected"
            :is-expand="isExpand"
            :template-list="templateList"
            :is-loading-template="isLoadingTemplate"
            :select="select"
            @handleExpandTemplate="handleExpandTemplate"
            @handleSelectTemplate="handleSelectTemplate"
            @handleDeleteTemplate="handleDeleteTemplate"
          />
          <div
            v-if="typeTemplateMessage.id === 0 || typeTemplateMessage.id === 2"
          >
            <div
              class="border border-1 mt-1 rounded-bl-0 rounded-br-0 rounded-md p-[1.3rem]"
            >
              <div>
                Tambah Variabel<span class="text-primary">*</span>
              </div>
            </div>
            <div
              class="border  border-1 border-t-0 rounded-md rounded-tl-0 rounded-tr-0 p-[1.3rem]"
            >
              <UiInputHtml
                :is-editor-enabled="spintaxContent.length? false : true"
                :spintaxContent="spintaxContent"
                :toolbars-id="'content'"
                :variable="true"
                :editor-options="editorOptionContent"
                :content="content"
                @update:content="(value) => {content = value}"
              />
            </div>
          </div>
          <div
            v-if="typeTemplateMessage.id === 1 || typeTemplateMessage.id === 2"
            class=" mb-1 mt-1"
          >
            <div class="text-[11.07px] mb-[3px]">
              <span class="text-primary">*</span>Foto
            </div>
            <b-input-group>
              <b-input-group-append
                class="flex border rounded-md border-[#cfcfcf] border-1 pl-[8px] items-center w-full"
                :class="error.media ? 'border-primary' : ''"
                @click="$refs.photoFile.click()"
              >
                <div
                  class="flex bg-primary rounded-sm p-[3px] items-center  w-[98px]"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/document-upload.svg"
                    alt=""
                    width="15px"
                  >
                  <span class="ml-[2px] text-[12px] text-white">
                    Pilih Foto</span>
                </div>
                <b-form-input
                  v-model="mediaName"
                  class=" bg-white"
                  style="border: none;"
                  :readonly="true"
                  :placeholder="mediaName ? mediaName : 'upload Foto'"
                />
              </b-input-group-append>
              <input
                id="poster"
                ref="photoFile"
                class="form-control"
                type="file"
                style="display: none"
                @change="validateExt"
              >
            </b-input-group>
            <div class="text-[9.22px] mt-[5px] text-[#828282]">
              <span class="text-primary">*</span>Upload foto dengan maksimal 1 Mb
            </div>
          </div>
          <div
            v-if="typeTemplateMessage.id === 2 || typeTemplateMessage.id === 1"
            class="border  border-1 border-t-0 rounded-md rounded-tl-0 rounded-tr-0 p-[1.3rem]"
          >
            <div class="mb-1">
              Caption
            </div>
            <div>
              <UiInputHtml
                :toolbars-id="'caption'"
                :editor-options="editorOptionCaption"
                :variable="true"
                :content="caption"
                @update:content="(value) => {caption = value}"
              />
            </div>
          </div>
          <div class="flex border border-1 p-[0.6rem] justify-end">
            <b-button
              :disabled="dissableSaveTemplate(typeTemplateMessage.value)"
              variant="primary"
              size="sm"
              @click="handleSaveTemplate()"
            >
              Simpan
            </b-button>
          </div>
        </b-col>
        <!-- preview -->
        <b-col>
          <div class="text-black md:hidden mt-3 mb-1 font-semibold">
            Preview
          </div>
          <div class="">
            <TemplatePreview
              :caption="caption"
              :content="content"
              :idTemplate="typeTemplateMessage.id"
              :urlMediaLocal="urlMediaLocal"
            />

            <DeliverySpeed
              :delivery-speed="deliverySpeed"
              :options-delivery-speed="optionsDeliverySpeed"
              :option-mode="optionMode"
              :mode="mode"
              :send-date="sendDate"
              :config-date="configDate"
              :send-time="sendTime"
              @update:sendTime="val => (sendTime = val)"
              @update:deliverySpeed="value => (deliverySpeed = value)"
              @update:mode="val => (mode = val)"
              @update:setSendDate="val => (sendDate = val)"
              @setSendTime="setSendTime"
            />
            <Spintax
              :templateTypeId="typeTemplateMessage.id"
              :content="content"
              @handleActionUse="onHandleActionUse"
              @handleCancelUseSpintax="onHandleCancelUseSpintax"
            />
            <div class="mt-2 mb-1">
              Kamu dapat menguji notifikasi ke no kamu sendiri :
            </div>
            <!-- <phoneInput :value-phone="customerPhone" /> -->
            <div>
              <b-input-group>
                <template #prepend>
                  <b-form-select
                    :options="phoneCodeList"
                    :value="phoneCode"
                  />
                </template>
                <b-form-input
                  v-model="customerPhone"
                  type="number"
                  maxLength="15"
                  :formatter="validatePhone"
                  @keypress="validateInputPhoneCustomer($event)"
                  @update="debounceCheckWhatsapp()"
                />
                <template #append>
                  <button
                    class="px-1 border rounded-sm border-2 cursor-pointer "
                    :disabled="disableSendPreviewButton()"
                    @click="sendDemo"
                  >
                    <img
                      :src="
                        !isDisableSendButton
                          ? 'https://storage.googleapis.com/komerce/assets/svg/send-message.svg'
                          : 'https://storage.googleapis.com/komerce/assets/icons/send-chat.svg'
                      "
                      alt="send"
                    ></button></template>
              </b-input-group>
              <div
                v-show="error.phone"
                class="text-primary text-xs"
              >
                {{ error.phone }}
              </div>
              <div
                v-if="isWhatsApp"
                class="flex gap-1 items-center text-xs"
                :class="isWhatsApp === 'valid' ? 'text-green' : 'text-primary'"
              >
                <b-img
                  v-if="isWhatsApp === 'valid'"
                  class="w-[12px]"
                  src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
                />
                <b-icon
                  v-else
                  icon="exclamation-triangle"
                  aria-hidden="false"
                />
                {{
                  isWhatsApp === 'valid'
                    ? 'Nomor ini terhubung dengan WhatsApp'
                    : 'Nomor ini tidak terhubung dengan whatsApp'
                }}
                <b-icon
                  icon="whatsapp"
                  aria-hidden="false"
                />
              </div>
            </div>
            <div class="flex items-center mt-2 mb-2 gap-2">
              <div class="flex-1 h-px bg-gray-400" />
              <p class="px-1 mb-[-3px]">
                atau
              </p>
              <div class="flex-1 h-px bg-gray-400" />
            </div>
            <div>
              <div>
                Kamu dapat mengirim broadcast langsung melalui tombol ini
              </div>
              <b-button
                :disabled="disableSendBroadcastButton()"
                class="w-full mt-1"
                variant="primary"
                size="sm"
                @click="sendBroadcast"
              >
                <div class="flex gap-1 justify-center items-center">
                  <b-img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/send-2.svg"
                    alt=""
                  />
                  <div class="text-sm">
                    Kirim Broadcast
                  </div>
                </div>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- modal -->
    <ModalDeleteTemplate @onHandleDeleteTemplate="onHandleDeleteTemplate()" />
  </div>
</template>

<script>
import UiInputHtml from '@/views/pages/komship/customer/Broadcast/components/UiInputHtml.vue'
import TemplatePreview from '@/views/pages/komship/customer/Broadcast/components/TemplatePreview.vue'
import Spintax from '@/views/pages/komship/customer/Broadcast/components/Spintax.vue'
import ModalDeleteTemplate from '@/views/pages/komship/customer/Broadcast/components/ModalDeleteTemplate.vue'
import SenderMessage from '@/views/pages/komship/customer/Broadcast/components/SenderMessage.vue'
import ReceiverMessage from '@/views/pages/komship/customer/Broadcast/components/ReceiverMessage.vue'
import SelectTemplate from '@/views/pages/komship/customer/Broadcast/components/SelectTemplate.vue'
import DeliverySpeed from '@/views/pages/komship/customer/Broadcast/components/DeliverySpeed.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BImg,
  BIcon,
} from 'bootstrap-vue'

/* eslint-disable import/no-extraneous-dependencies */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
// import '../opsional/wa-notif/template-notifikasi/overwrite-quill.css'
import { Indonesian } from 'flatpickr/dist/l10n/id'

import { toInteger } from 'lodash'
import moment from 'moment'
import {
  isWhatsAppHasScan,
  optionsDeliverySpeed,
  optionsTemplate,
  configDate,
  optionMode,
  editorOptionCaption,
  editorOptionContent,
} from '@/views/pages/komship/customer/Broadcast/config'
import {
  apiGetTemplate,
  apiDeleteTemplate,
  apiSaveTemplate,
  apiGetTemplateById,
} from './Broadcast/service/api.template.service'
import { apiGetGroupContact } from './Broadcast/service/api.groupContact.service'
import apiCheckWhatsApp from './Broadcast/service/api.checkWhatsApp.service'
import { apiSendBroadcast, apiSendPreview, apiSaveTempImage } from './Broadcast/service/api.broadcast.service'

export default {
  components: {
    ModalDeleteTemplate,
    SenderMessage,
    ReceiverMessage,
    SelectTemplate,
    DeliverySpeed,
    BCard,
    BRow,
    BCol,
    BIcon,
    BFormInput,
    Spintax,
    TemplatePreview,
    UiInputHtml,
  },
  data() {
    return {
      isLoading: false,
      loading: {
        saveTemplate: false,
        template: false,
        groupContact: false,
      },
      editorOptionContent,
      editorOptionCaption,
      spintaxStatus: null,
      addressName: '',
      originValue: '',
      searchReceiver: '',
      cursor: 0,
      limit: 10,
      whatsAppList: this.$store.state.komchat.whatsAppList,
      error: {
        phone: '',
        media: '',
      },
      templateName: '',
      mediaName: '',
      media: null,
      urlMediaLocal: '',
      urlMedia: '',
      phoneCodeList: ['+62'],
      phoneCode: '+62',
      customerPhone: null,
      content: '',
      caption: '',
      isDisableSendButton: false,
      isExpand: false,
      select: null,
      previewChatbox: '',
      senderNumber: '',
      typeTemplateMessage: {
        label: 'Pesan Teks',
        value: 'TEXT',
        id: 0,
      },
      templateSelected: null,
      templateContent: {},
      templateId: null,
      templateList: [],
      isLoadingTemplate: false,
      optionSenderNumber: [],
      deliverySpeed: '',
      mode: '',
      isWhatsApp: '',
      debounce: 0,
      isGroupContactEmpty: false,
      groupContact: '',
      groupContactSelected: [],
      isLoadingGroupContact: false,
      sendDate: '',
      sendTime: '',
      optionsDeliverySpeed,
      optionsTemplate,
      configDate: {
        wrap: true,
        altFormat: 'j F Y',
        altInput: true,
        minDate: 'today',
        altInputClass: 'bg-white form-control',
        locale: Indonesian,
      },
      optionMode,
      moment,
      dialogHidden: true,
      dialogContentHidden: true,
      isLastData: {
        groupContact: false,
      },
      spintaxContent: [],
    }
  },
  async mounted() {
    this.getGroupContact()
    this.defineListNumber()
    this.getCurrentDate()
  },
  methods: {
    onHandleCancelUseSpintax() {
      this.spintaxContent = []
    },
    onHandleActionUse(value) {
      const contentOri = {
        content: this.content,
      }
      this.spintaxContent = value
      this.spintaxContent.unshift(contentOri)
    },
    async getGroupContact() {
      this.isLoadingGroupContact = true
      this.cursor = 0
      try {
        const data = await apiGetGroupContact(
          this.searchReceiver,
          this.cursor,
          this.limit,
        )
        const groupContact = data.data.data
        this.cursor = data.data.meta.pagination.next_cursor
        if (groupContact.length < this.limit || this.cursor === undefined) {
          this.isLastData.groupContact = true
        } else {
          this.isLastData.groupContact = false
        }
        if (!groupContact) {
          this.groupContact = []
        } else {
          this.groupContact = groupContact
        }
        this.isLoadingGroupContact = false
      } catch (error) {
        this.$toast_error({ message: error.response.data.meta.message })
        this.groupContact = []
        this.isLoadingGroupContact = false
      }
    },
    async getNextGroupContact() {
      if (!this.isLastData.groupContact) {
        this.isLoadingGroupContact = true
        try {
          const data = await apiGetGroupContact(
            this.searchReceiver,
            this.cursor,
            this.limit,
          )
          const groupContact = data.data.data
          this.groupContact.push(...groupContact)
          this.cursor = data.data.meta.pagination.next_cursor
          if (groupContact.length < this.limit || this.cursor === undefined) {
            this.isLastData.groupContact = true
          } else {
            this.isLastData.groupContact = false
          }
          this.isLoadingGroupContact = false
        } catch (error) {
          console.error(error)
          this.$toast_error({ message: error.response.data.meta.message })
          this.isLoadingGroupContact = false
        }
      }
    },
    async getTemplate() {
      this.isLoadingTemplate = true
      try {
        const data = await apiGetTemplate()
        this.templateList = data.data.data
        this.isLoadingTemplate = false
      } catch (error) {
        this.$toast_error({ message: error.response.data.meta.message })
        this.isLoadingTemplate = false
      }
    },
    handleExpandTemplate() {
      this.isExpand = !this.isExpand
      if (this.isExpand && !this.templateList.length) {
        this.getTemplate()
      }
    },
    handleSelectTemplate(value) {
      if (this.select === value.id) {
        this.templateSelected = null
        this.select = null
        this.templateContent = {}
        this.urlMediaLocal = ''
        this.caption = ''
        this.templateName = ''
        this.content = ''
        this.templateId = null
      } else {
        this.select = value.id
        this.templateSelected = value
        this.getTemplateById(value.id)
      }
    },
    async getTemplateById(id) {
      try {
        const response = await apiGetTemplateById(id)
        this.templateContent = response.data.data
        this.defineTypeTemplateBySelect(response.data.data)
      } catch (error) {
        this.$toast_error({ message: error.response.data.meta.message })
      }
    },
    defineTypeTemplateBySelect(template) {
      if (template.type === 'TEXT') {
        this.typeTemplateMessage.label = 'Pesan Teks'
        this.typeTemplateMessage.value = 'TEXT'
        this.typeTemplateMessage.id = 0
        this.content = this.convertWhatsappToHtml(template.content)
        this.templateName = template.name
        this.templateId = template.id
      } else if (template.type === 'MEDIA') {
        this.typeTemplateMessage.label = 'Pesan Media'
        this.typeTemplateMessage.value = 'MEDIA'
        this.typeTemplateMessage.id = 1
        this.urlMediaLocal = template.media_url
        this.urlMedia = template.media_url
        this.caption = this.convertWhatsappToHtml(template.media_caption)
        this.templateName = template.name
        this.templateId = template.id
      } else {
        this.typeTemplateMessage.label = 'Pesan Teks dan Pesan Media'
        this.typeTemplateMessage.value = 'TEXT_MEDIA'
        this.typeTemplateMessage.id = 2
        this.urlMediaLocal = template.media_url
        this.urlMedia = template.media_url
        this.caption = this.convertWhatsappToHtml(template.media_caption)
        this.templateName = template.name
        this.content = this.convertWhatsappToHtml(template.content)
        this.templateId = template.id
      }
    },
    async handleSaveTemplate() {
      try {
        const type = this.typeTemplateMessage.value
        if (type !== 'TEXT' && (this.urlMedia !== this.urlMediaLocal)) {
          await this.handleSaveImageTemp()
        }
        const params = await this.defineParamsByTypeTemplate(type)
        const data = await apiSaveTemplate({ params })
        if (data.data.meta.code === 200) {
          this.$toast_success({ message: 'Berhasil Menyimpan Template' })
          this.getTemplate()
        }
      } catch (error) {
        this.$toast_error({ message: error.response.data.meta.message })
      }
    },
    async defineParamsByTypeTemplate(type) {
      const content = await this.convertHtmlToWhatsapp(this.content)
      const caption = await this.convertHtmlToWhatsapp(this.caption)
      const data = {
        name: this.templateName,
        type: this.typeTemplateMessage.value,
      }
      if (this.templateId) {
        data.id = this.templateId
      }
      if (type === 'TEXT') {
        data.content = content
      } else if (type === 'MEDIA') {
        data.media_caption = caption
        data.media_url = this.urlMedia
      } else {
        data.content = content
        data.media_caption = caption
        data.media_url = this.urlMedia
      }
      return data
    },
    async defineParamsByTypeTemplateSendPreview(type) {
      const content = await this.convertHtmlToWhatsapp(this.content)
      const caption = await this.convertHtmlToWhatsapp(this.caption)
      const data = {
        type: this.typeTemplateMessage.value,
        sender_phone_id: this.senderNumber.id,
        receiver_phone_number: `0${this.customerPhone}`,
      }
      if (type === 'TEXT') {
        data.content = content
      } else if (type === 'MEDIA') {
        data.media_caption = caption
        data.media_url = this.urlMedia
      } else {
        data.content = content
        data.media_caption = caption
        data.media_url = this.urlMedia
      }
      return data
    },
    async defineParamsByTypeTemplateSendBroadcast(type) {
      let content = []

      if (this.spintaxContent.length) {
        content = await Promise.all(
          this.spintaxContent.map(item => this.convertHtmlToWhatsapp(item.content)),
        )
      } else {
        const converted = await this.convertHtmlToWhatsapp(this.content)
        content = [converted]
      }

      const contents = content
      const caption = await this.convertHtmlToWhatsapp(this.caption)
      const data = {
        title: this.templateName,
        type: this.typeTemplateMessage.value,
        mode: this.mode.value,
        schedule: `${this.sendDate} ${this.sendTime}`,
        interval: this.deliverySpeed.value,
        is_rotator_sender: this.senderNumber.id === 0,
        sender_phone_id: this.senderNumber.id === 0 ? null : this.senderNumber.id,
        group_contact_ids: this.groupContactSelected.map(item => item.id),
      }
      if (type === 'TEXT') {
        data.contents = contents
      } else if (type === 'MEDIA') {
        data.media_caption = caption
        data.media_url = this.urlMedia
      } else {
        data.contents = contents
        data.media_caption = caption
        data.media_url = this.urlMedia
      }
      return data
    },
    resetFormTemplate() {
      this.templateSelected = null
      this.select = null
      this.templateContent = {}
      this.urlMediaLocal = ''
      this.urlMedia = ''
      this.caption = ''
      this.templateName = ''
      this.content = ''
      this.templateId = null
    },
    validatePhone(value) {
      // Reset error message
      this.error.phone = null
      if (!value) {
        this.isWhatsApp = null
        this.error.phone = 'Nomor telepon harus diisi'
        return value
      }
      value.replace(/[^\d]/g, '')
      if (value.startsWith('62')) {
        return value.slice(2)
      }
      if (!value.startsWith('8')) {
        this.isWhatsApp = null
        this.error.phone = 'Dimulai dari angka 8'
        return value.slice(1)
      }
      if (value.length < 8) {
        this.isWhatsApp = null
        this.error.phone = 'Minimal 8 angka'
      }
      if (value.length > 12) {
        this.isWhatsApp = null
        this.error.phone = 'Maksimal 12 karakter'
        return value.substring(0, 13)
      }
      return value
    },
    validateInputPhoneCustomer(e) {
      const charCode = e.which ? e.which : e.keyCode
      const inputValue = String.fromCharCode(charCode)
      const validCharacters = /^[0-9]*$/
      if (!validCharacters.test(inputValue)) {
        e.preventDefault()
      }
    },
    async handleSaveImageTemp() {
      try {
        const formData = new FormData()
        formData.append('file', this.media)
        const { data } = await apiSaveTempImage({ formData })
        this.urlMedia = data.data.url
      } catch (error) {
        this.$toast_error({ message: 'Gagal Menyimpan Image' })
        console.error(error)
      }
    },
    debounceCheckWhatsapp() {
      // eslint-disable-next-line no-underscore-dangle
      clearTimeout(this.debounce)

      // eslint-disable-next-line no-underscore-dangle
      this.debounce = setTimeout(() => {
        this.checkWhatsApp()
      }, 1000)
    },
    async checkWhatsApp() {
      if (!this.error.phone) {
        try {
          const { data } = await apiCheckWhatsApp(this.customerPhone)
          this.isWhatsApp = data.data
          this.error.phone = ''
        } catch (error) {
          this.isWhatsApp = error.response.data.data
          this.error.phone = ''
        }
      }
    },
    async sendDemo() {
      try {
        const type = this.typeTemplateMessage.value
        if (type !== 'TEXT' && (this.urlMedia !== this.urlMediaLocal)) {
          await this.handleSaveImageTemp()
        }
        const param = await this.defineParamsByTypeTemplateSendPreview(type)
        const data = await apiSendPreview({ param })
        if (data.data.meta.code === 200) {
          this.$toast_success({ message: 'Berhasil Mengirim Preview Broadcast' })
        }
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengirim Preview Broadcast' })
        console.error(error)
      }
    },
    async sendBroadcast() {
      const type = this.typeTemplateMessage.value
      if (type !== 'TEXT' && (this.urlMedia !== this.urlMediaLocal)) {
        await this.handleSaveImageTemp()
      }
      const param = await this.defineParamsByTypeTemplateSendBroadcast(type)
      try {
        const data = await apiSendBroadcast({ param })
        if (data.data.meta.code === 200) {
          this.$toast_success({ message: 'Berhasil Mengirim Broadcast' })
          this.$router.push('/broadcast')
        }
      } catch (error) {
        console.error(error)
        this.$toast_error({ message: error.response.data.meta.message })
      }
    },
    handleDeleteTemplate(id) {
      this.templateId = id
      this.$refs['modal-delete-template'].show()
    },
    async onHandleDeleteTemplate() {
      try {
        const data = await apiDeleteTemplate(this.templateId)
        if (data.data.meta.code === 200) {
          this.$toast_success({ message: 'Berhasil Menghapus Template' })
          this.getTemplate()
        }
      } catch (error) {
        this.$toast_error({ message: error.response.data.meta.message })
      } finally {
        this.hideModal()
      }
    },
    async defineListNumber() {
      const listWA = await isWhatsAppHasScan(this.whatsAppList)

      this.optionSenderNumber = listWA ? listWA.map(item => ({
        label: item.phone_number,
        id: item.id,
      })) : []

      if (listWA && listWA.length > 1) {
        this.optionSenderNumber.push({ label: 'Rotate', id: 0 })
      }
    },
    formatSendTime(value) {
      const now = moment()
      return now.hour(value).format('HH:mm:ss')
    },
    getCurrentDate() {
      const hours = moment().format('hh:mm:ss')
      const minDate = moment()
        .add(1, 'days')
        .format('YYYY-MM-DD')
      if (hours >= 21) {
        this.configDate.minDate = minDate
        this.sendDate = minDate
      } else {
        this.sendDate = moment().format('YYYY-MM-DD')
      }
      this.sendTime = hours
    },
    setSendTime(action) {
      const today = moment()
      const minHours = today.format('HH')
      const sendTime = toInteger(this.sendTime.slice(0, 2))
      if (action === 'plus') {
        if (sendTime >= 23) {
          this.sendTime = this.formatSendTime(23)
        } else {
          this.sendTime = this.formatSendTime(sendTime + 1)
        }
      } else if (action === 'minus') {
        if (sendTime <= 0) {
          this.sendTime = this.formatSendTime(0)
        } else if (this.sendDate === today.format('YYYY-MM-DD')) {
          this.sendTime = this.formatSendTime(minHours)
        } else {
          this.sendTime = this.formatSendTime(sendTime - 1)
        }
      } else {
        this.sendTime = action
      }
    },
    validateExt(evChange) {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
        && fileExtension !== 'gif'
      ) {
        this.error.media = 'Hanya dapat upload file dengan ekstensi JPG dan PNG'
        this.mediaName = ''
      } else {
        this.mediaName = evChange.target.files[0].name
        this.validateSize(evChange)
      }
    },
    validateSize(evChange) {
      const fileUpload = evChange.target
      const file = fileUpload.files[0]

      if (file) {
        const maxSize = 1024 * 1024
        if (file.size > maxSize) {
          this.error.media = 'Ukuran file terlalu besar. Maksimal 1 MB.'
          this.media = {}
          this.mediaName = ''
        } else {
          this.media = file
          this.urlMediaLocal = URL.createObjectURL(file)
        }
      }
    },
    handleSearchReceiver(value) {
      clearTimeout(this.debounce)

      // eslint-disable-next-line no-underscore-dangle
      this.debounce = setTimeout(() => {
        this.searchReceiver = value
        this.getGroupContact()
      }, 2000)
    },

    convertHtmlToWhatsapp(htmlText) {
    // Mengganti tag paragraf dengan baris baru
      let plainText = htmlText.replace(/<\/?p>/g, match => (match === '</p>' ? '\n' : ''))
      // Mengganti <br> dengan baris baru
      plainText = plainText.replace(/<br\s*\/?>/g, '\n')
      // Mengganti tag <strong> dengan tanda bintang
      plainText = plainText.replace(/<\/?strong>/g, '*')
      // Mengganti tag <em> dengan underscore
      plainText = plainText.replace(/<\/?em>/g, '_')
      // Mengganti tag <s> dengan tilde
      plainText = plainText.replace(/<\/?s>/g, '~')
      // Mengganti tag <li> dengan tanda titik dan baris baru
      plainText = plainText.replace(/<\/?li>/g, match => (match === '</li>' ? '' : '\n- '))
      // Mengganti tag <ul> dan </ul> dengan spasi baru untuk menjaga format list
      plainText = plainText.replace(/<\/?ul>/g, '')
      // Mengganti tag <ol> dan </ol> dengan baris baru
      plainText = plainText.replace(/<\/?ol>/g, '\n')
      // Mengganti spasi di dalam tag
      plainText = plainText.replace(/>\s+</g, '><')
      // Mengganti spasi di dalam tag ganda
      plainText = plainText.replace(/<strong>\s*<s>(.*?)<\/s>\s*<\/strong>/g, '*~$1~*')
      plainText = plainText.replace(/<em>\s*<strong>\s*<s>(.*?)<\/s>\s*<\/strong>\s*<\/em>/g, '_*~$1~*_')
      // Mengatasi spasi di dalam tag triple
      plainText = plainText.replace(/<em>\s*<strong>\s*<s>\s*(.*?)\s*<\/s>\s*<\/strong>\s*<\/em>/g, '_*~$1~*_')
      // Mengganti tag yang nested dengan urutan bebas
      plainText = plainText.replace(/<em>\s*<strong>(.*?)<\/strong>\s*<\/em>/g, '_*$1*_')
      plainText = plainText.replace(/<strong>\s*<em>(.*?)<\/em>\s*<\/strong>/g, '*_$1_*')
      plainText = plainText.replace(/<em>\s*<s>(.*?)<\/s>\s*<\/em>/g, '_~$1~_')
      plainText = plainText.replace(/<s>\s*<em>(.*?)<\/em>\s*<\/s>/g, '~_$1_~')
      plainText = plainText.replace(/<strong>\s*<s>(.*?)<\/s>\s*<\/strong>/g, '*~$1~*')
      plainText = plainText.replace(/<s>\s*<strong>(.*?)<\/strong>\s*<\/s>/g, '~*$1*~')
      // Menjaga spasi di dalam teks
      plainText = plainText.replace(/(\S)(<[^>]+>)(\s+)(<[^>]+>)(\S)/g, '$1$2 $4$5')
      // Menghapus tag HTML yang tersisa
      plainText = plainText.replace(/<\/?[^>]+(>|$)/g, '')
      return plainText.trim()
    },

    convertWhatsappToHtml(whatsAppText) {
      // Mengganti format WhatsApp dengan tag HTML
      let htmlText = whatsAppText.replace(/\*(.*?)\*/g, '<strong>$1</strong>')
        .replace(/_(.*?)_/g, '<em>$1</em>')
        .replace(/~(.*?)~/g, '<s>$1</s>')
      // Mengganti list bullet point WhatsApp dengan tag <ul> dan <li>
      htmlText = htmlText.replace(/\n- (.*?)(?=\n|$)/g, '<li>$1</li>')
      htmlText = htmlText.replace(/(?:<li>.*<\/li>)+/g, '<ul>$&</ul>')
      // Mengganti baris baru dengan <br>, kecuali yang sudah di dalam <li>
      htmlText = htmlText.replace(/(?!<\/?li>)(\n+)/g, match => '<br>'.repeat(match.length))
      // Membungkus hasil dalam tag paragraf jika belum ada
      if (!htmlText.includes('<p>')) {
        htmlText = `<p>${htmlText}</p>`
      } else {
        htmlText = `<p>${htmlText}</p>`.replace(/<p><\/p>/g, '') // Menghapus <p></p> yang kosong
      }
      return htmlText
    },

    dissableSaveTemplate(type) {
      const disabled = {
        TEXT: !this.content || !this.templateName,
        MEDIA: !this.caption || !this.urlMediaLocal || !this.templateName,
        TEXT_MEDIA:
          !this.caption
          || !this.urlMediaLocal
          || !this.templateName
          || !this.content
          || !this.templateName,
      }
      return disabled[type]
    },

    disableSendPreviewButton() {
      if (this.error.phone || !this.customerPhone || this.isWhatsApp !== 'valid' || this.dissableSaveTemplate(this.typeTemplateMessage.value) || !this.senderNumber) {
        this.isDisableSendButton = true
        return true
      }
      this.isDisableSendButton = false
      return false
    },
    disableSendBroadcastButton() {
      return this.dissableSaveTemplate(this.typeTemplateMessage.value)
         || !this.senderNumber
         || !this.mode
         || !this.deliverySpeed
         || !this.groupContactSelected.length
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

.preview {
  display: none
}

@media only screen and (min-width: 700px) {
  .preview {
    display: block !important
  }
}
</style>
