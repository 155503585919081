<template>
  <div class="border border-1 mt-1 rounded-md p-[1.3rem]">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <div class="flex items-center"><span class="text-primary">*</span>Interval Pengiriman <span>
          <img
            id="balanceTooltip"
            class="align-self-center ml-[2px]"
            width="16"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
          >
          <b-popover
            triggers="hover"
            target="balanceTooltip"
            placement="top"
          >selisih waktu pengiriman dari satu pesan ke pesan lain</b-popover></span></div>
        <div>
          <v-select
            :value="deliverySpeed"
            :options="optionsDeliverySpeed"
            label="label"
            placeholder="Pilih Kecepatan Kirim"
            @input="$emit('update:deliverySpeed', $event)"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <div><span class="text-primary">*</span>Mode</div>
        <div>
          <v-select
            :value="mode"
            :options="optionMode"
            label="label"
            placeholder="Pilih Mode"
            @input="$emit('update:mode',$event )"
          />
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="handleModeSchedule()"
      class="mt-2"
    >
      <b-col>
        <div class="text-[14px] text-[400] text-[#333333]">
          <span class="text-primary">*</span>Atur Waktu Kirim
        </div>
        <div class="flex gap-2 items-center">
          <b-input-group class="rounded max-w-[154px]">
            <flat-pickr
              ref="pickupDate"
              :value="sendDate"
              :config="configDate"
              @input="handleInputDate($event)"
            />
          </b-input-group>
          <div class="flex  items-center">
            <b-form-timepicker
              :value="sendTime"
              locale="id"
              class="!h-[36px]"
              @input="handleSendTime($event)"
            />
            <div class="ml-1 max-h-[40px]">
              <b-icon-chevron-up
                role="button"
                @click="$emit(`setSendTime`,'plus')"
              /><br>
              <b-icon-chevron-down
                role="button"
                @click="$emit('setSendTime','minus')"
              />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'flatpickr/dist/themes/light.css'
import flatPickr from 'vue-flatpickr-component'
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BOverlay,
  BFormCheckbox,
  BSpinner,
  BModal,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    VSelect,
    flatPickr,
    BRow,
    BCol,
  },
  props: {
    deliverySpeed: {
      type: [String, Object],
      default: '',
    },
    optionsDeliverySpeed: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: [Number, String, Object],
      default: 0,
    },
    optionMode: {
      type: Array,
      default: () => [],
    },
    sendDate: {
      type: String,
      default: '',
    },
    configDate: {
      type: Object,
      default: () => {},
    },
    sendTime: {
      type: String,
      default: '',
    },
    disableTimePicker: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInputDate(value) {
      this.$emit('update:setSendDate', value)
    },
    handleModeSchedule() {
      return this.mode?.value === 'SCHEDULED' || false
    },
    handleSendTime(event) {
      this.$emit('update:sendTime', event)
      this.$emit('setSendTime', event)
    },

  },
}
</script>
<style lang="scss">

@import '~@core/scss/vue/libs/vue-flatpicker.scss';
#__BVID__519__outer_ {
height: 36px !important;
}

</style>
