import { newAxiosIns } from '@/libs/axios'

const URL = '/komchat-api/api/v1/template'

export function apiGetTemplate() {
  return newAxiosIns.get(URL)
}

export function apiDeleteTemplate(id) {
  return newAxiosIns.delete(`${URL}/${id}`)
}

export function apiSaveTemplate({ params }) {
  return newAxiosIns.post(`${URL}/save`, params)
}

export function apiGetTemplateById(id) {
  return newAxiosIns.get(`${URL}/${id}`)
}
